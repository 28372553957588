import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../../components/frontend/auth/AuthUtility';

import OffCanvasNav from './OffCanvasNav';

import swal from 'sweetalert';

import logo from '../../assets/frontend/images/logo.png';

const OnCanvasHeader = () => {
	
	//logout should be its own component
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);

	const logoutSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiData(response);
			} catch (error) {
			  handleApiError(error);
			}
		};
	  
		fetchData();
	}
    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		setIsLoading(false);
		
		navHistory('/login');
    }

	var RoleDashboardLink = '';
	var RoleProfileLink = '';
	var Navigation = '';

	if(AuthUtility.isLoggedIn()){
		
		RoleProfileLink = '/' + localStorage.getItem('auth_role') + '/profile';
		if(localStorage.getItem('auth_role') === 'member'){
			RoleDashboardLink = '/member/dashboard';
			RoleProfileLink = '/member/profile';
		}else if(localStorage.getItem('auth_role') === 'admin'){
			RoleDashboardLink = '/admin/dashboard';
			RoleProfileLink = '/admin/profile';
		}
	
		Navigation = (
			<nav className="nav right">
				<ul>
					<li className="dropdown text-center">
						<Link to="/home" className="menu1 font-raleway font-small font-weight-500 uppercase">Home</Link>
						<ul>
							<li className="menu1"><Link to="/hiit" className="menu1 font-raleway font-small font-weight-500 uppercase">HIIT</Link></li>
							<li className="menu1"><Link to="/yoga" className="menu1 font-raleway font-small font-weight-500 uppercase">Yoga</Link></li>
							<li className="menu1"><Link to="/search" className="menu1 font-raleway font-small font-weight-500 uppercase">Search</Link></li>
							<li className="menu1"><Link to="/about" className="menu1 font-raleway font-small font-weight-500 uppercase">About</Link></li>
							<li className="menu1"><Link to="/help" className="menu1 font-raleway font-small font-weight-500 uppercase">Help</Link></li>
							<li className="menu1"><Link to="/testimonials" className="menu1 font-raleway font-small font-weight-500 uppercase">Testimonials</Link></li>
							<li className="menu1"><Link to="/contact" className="menu1 font-raleway font-small font-weight-500 uppercase">Contact</Link></li>
						</ul>
					</li>
					<li className="dropdown text-center">
						<Link to={RoleProfileLink} className="menu3 font-raleway font-small font-weight-500 uppercase">{localStorage.getItem('auth_first_name') + ' ' + localStorage.getItem('auth_last_name').charAt(0).toUpperCase()+ '.'}</Link>
						<ul>
							<li className="menu3"><Link to={RoleProfileLink} className="menu3 font-raleway font-small font-weight-500 uppercase">Profile</Link></li>
							<li className="menu3"><Link to="#" className="menu3 font-raleway font-small font-weight-500 uppercase" onClick={logoutSubmit}>Logout</Link></li>
						</ul>
					</li>
				</ul>
			</nav>
		);
	}else{
	
		Navigation = (
			<nav className="nav right">
				<ul>
					<li className="menu1"><Link to="/login" className="font-raleway font-small font-weight-500 uppercase">Login</Link></li>
					<li className="menu1"><Link to="/register" className="font-raleway font-small font-weight-500 uppercase">Register</Link></li>
					<li className="dropdown text-center">
						<Link to="/home" className="menu1 font-raleway font-small font-weight-500 uppercase">Home</Link>
						<ul>
							<li className="menu1"><Link to="/hiit" className="menu1 font-raleway font-small font-weight-500 uppercase">HIIT</Link></li>
							<li className="menu1"><Link to="/yoga" className="menu1 font-raleway font-small font-weight-500 uppercase">Yoga</Link></li>
							<li className="menu1"><Link to="/search" className="menu1 font-raleway font-small font-weight-500 uppercase">Search</Link></li>
							<li className="menu1"><Link to="/about" className="menu1 font-raleway font-small font-weight-500 uppercase">About</Link></li>
							<li className="menu1"><Link to="/help" className="menu1 font-raleway font-small font-weight-500 uppercase">Help</Link></li>
							<li className="menu1"><Link to="/testimonials" className="menu1 font-raleway font-small font-weight-500 uppercase">Testimonials</Link></li>
							<li className="menu1"><Link to="/contact" className="menu1 font-raleway font-small font-weight-500 uppercase">Contact</Link></li>
						</ul>
					</li>
				</ul>
			</nav>
		);
	}

	return(
		<div className="sticky-top z-index-1000">
			<div className="sticky bg-fafafa bb1-ddd plr-20l-10sz">
				
				<div className="panel large">
				
					<div id="nav_bar" className="nav-bar">
						<div className="nav-bar-left">
							<div className="clearfix">
								<Link to="/" className="hover-opacity-50 hide-for-small-only">
									<span className="left">
										<img src={logo} alt="logo" width="55"/>
									</span>
									<span className="text-left pl-10 left">
										<div className="font-raleway font-xx-large font-weight-700 txt-avw-dark-green uppercase pt-5 pb-3">ALLY VON WOLF</div>
										<div className="font-raleway font-large font-weight-600 txt-avw-dark-green letter-spacing-0px uppercase">Join The Wolf Pack</div>
									</span>
								</Link>
								<Link to="/" className="hover-opacity-50 show-for-small-only">
									<span className="left">
										<img src={logo} alt="logo" width="40"/>
									</span>
									<span className="text-left pl-7 left">
										<div className="font-raleway font-x-large font-weight-700 txt-avw-dark-green uppercase pt-2 pb-3">ALLY VON WOLF</div>
										<div className="font-raleway font-standard font-weight-600 txt-avw-dark-green uppercase">Join The Wolf Pack</div>
									</span>
								</Link>
							</div>
						</div>
						<div className="nav-bar-right hide-for-small-only">
							{Navigation}
						</div>
						<div className="nav-bar-right show-for-small-only">
							<OffCanvasNav />
						</div>
					</div>
				</div>
			
			</div>
		</div>
	);
}

export default OnCanvasHeader;