import React from 'react';
import {Link} from 'react-router-dom';


function Dashboard(){
    
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-large-medium-mr font-weight-700 txt-333 uppercase bb1-333 pb-5">Dashboard</div>
					</div>
				</div>
			</div>
		
			<div className="panel large">

				<div className="grid-x">
					
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/check_list" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/portfolio" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/traffic" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/weather" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/videos" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
					<div className="large-3 medium-4 small-6 cell text-left pt-20">
						<Link to="/member/payments" className="icon-with-text hover-opacity-50 no-underline">
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;