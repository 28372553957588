import React, { useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
	
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import useClassStatusUpdater from './ClassUtilities';

import swal from 'sweetalert';

function ClassViewer(){

	const navHistory = useNavigate();

    const { handleUpdateUserClassStatus, isLoadingAtTop } = useClassStatusUpdater(null);

	const booked_user_class_random_id = localStorage.getItem('booked_user_class_random_id');
	const booked_class_recording_url = localStorage.getItem('booked_class_recording_url');
	const booked_group_title = localStorage.getItem('booked_group_title');
	const booked_class_title = localStorage.getItem('booked_class_title') || 'all booked classes';

	// Initial call for user list items
	useEffect(() => {
		if(!AuthUtility.isLoggedIn()){
			AuthUtility.clearAuthData();

			swal("Info","Please login to view the class","info");
		}else if(!AuthUtility.isSubscribed()){
			
			//swal("Info","<div className='font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify'>Please subscribe to book "+class_title+'. To subscribe navigate to your account page.</div>',"info");
			swal({
				title: "Subscribe to View a Booked Class",
				text: "Please subscribe to view "+booked_class_title+ ". Subscription options are in your Account.",
				icon: "info",
				buttons: {
					cancel: "Ok",
					confirm: {
						text: "Go to Account",
						value: true,
					},
				},
			}).then((value) => {
				if (value) {
					navHistory('/member/account');
				}
			});
		}else if(!localStorage.getItem('booked_user_class_random_id') || !localStorage.getItem('booked_class_recording_url')){
			swal("Error","An error occurred when trying to locate the class","error");
			navHistory('/member/booked');
		}else{
			swal("Info","Once you complete the "+booked_class_title+" class please click the DONE button below to update your class history.","info");
		}
	}, [navHistory, booked_user_class_random_id, booked_class_recording_url, booked_class_title]);

	const handleClassComplete = (event, booked_user_class_random_id) => {
		event.preventDefault();

		handleUpdateUserClassStatus(event, booked_user_class_random_id, 'viewed');
	};

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5">
					<div className="large-11 medium-11 small-11 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase">{localStorage.getItem('booked_class')}</div>
					</div>
					<div className="large-1 medium-1 small-1 cell text-right">
						{isLoadingAtTop &&
						<LoadingSpinner sizeClass="tiny" />
						}
					</div>
				</div>
			</div>

			{!booked_class_recording_url ? (
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fafafa b1-ddd p-20l-10s">	
					<div className="large-12 medium-12 small-12 cell pl-10l-0s">
						<div className="font-raleway font-medium font-weight-700 txt-333 text-left-center-on-small">No Class Video.</div>
					</div>
				</div>
			</div>
			) : (
				AuthUtility.isSubscribed() &&
				<div className="panel large pt-20l-10s">
					<div className="grid-x">
						<div className="large-12 medium-12 small-12 cell">
							{/*
							<video controls className="full-width-video">
							<source src={booked_class_recording_url} type="video/mp4" />
							Your browser does not support the video tag.
							</video>
							*/}
							<div class="flex-video widescreen vimeo z-index-1035">
								<iframe id="youtube_video" src={`${booked_class_recording_url}?autoplay=1`}
									frameborder="0" allow="autoplay"
									style={{ width: '100%', height: '100%' }}>
								</iframe>
							</div>
						</div>
						<div className="large-12 medium-12 small-12 cell text-center pt-20">
							<Link onClick={(e) => handleClassComplete(e, booked_user_class_random_id)} className="button p-10 uppercase" valign="top">Done</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);

};

export default ClassViewer;

/*

						<div className="video-container">
							<video controls>
							<source src={aws_s3_url + '/' + video.video_url} type="video/mp4" />
							Your browser does not support the video tag.
							</video>
						</div>
						*/