const AuthUtility = {
	setAuthData: (first_name, last_name, email, pay_type, token, role, password, remember_me) => {

		localStorage.setItem('auth_first_name', first_name);
        localStorage.setItem('auth_last_name', last_name);
        localStorage.setItem('auth_email', email);

		if(pay_type){
			localStorage.setItem('auth_pay_type', pay_type);
		}

        localStorage.setItem('auth_token', token);
        localStorage.setItem('auth_role', role);
        
        if (typeof remember_me === 'boolean') {
			if(remember_me){
	        	localStorage.setItem('password', password);
	        	localStorage.setItem('remember_me', 'true');
			}else{
	        	localStorage.removeItem('password');
	        	localStorage.removeItem('remember_me');
			}
		}
	},
	//setSubscriptionData: (pay_type, profile_subscription, payment_status) => {
	setSubscriptionData: (subscription_plan, subscription_start_date, subscription_end_date, subscription_is_valid) => {
		if(subscription_plan){
			localStorage.setItem('auth_subscription_plan', subscription_plan);
		}
		if(subscription_start_date){
			localStorage.setItem('auth_subscription_start_date', subscription_start_date);
		}
		if(subscription_end_date){
			localStorage.setItem('auth_subscription_end_date', subscription_end_date);
		}
		if (subscription_is_valid !== null && subscription_is_valid !== undefined) {
			localStorage.setItem('auth_subscription_is_valid', subscription_is_valid);
		}
	},
	clearAuthData: () => {

		if(!localStorage.getItem('remember_me') || localStorage.getItem('remember_me') !== 'true'){
        	localStorage.removeItem('auth_first_name');
        	localStorage.removeItem('auth_last_name');
        	localStorage.removeItem('auth_email');
        	localStorage.removeItem('password');
		}

		localStorage.removeItem('auth_pay_type');

		localStorage.removeItem('auth_subscription_plan');
		localStorage.removeItem('auth_subscription_start_date');
		localStorage.removeItem('auth_subscription_end_date');
		localStorage.removeItem('auth_subscription_is_valid');
			
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_role');
	},
	getAuthData: () => {
	    const first_name = localStorage.getItem('auth_first_name');
	    const last_name = localStorage.getItem('auth_last_name');
	    const email = localStorage.getItem('auth_email');
	    const pay_type = localStorage.getItem('auth_pay_type');
	    const token = localStorage.getItem('auth_token');
	    const role = localStorage.getItem('auth_role');

	    return { first_name, last_name, email, pay_type, token, role };
	},
	getSubscriptionData: () => {
		

		localStorage.removeItem('auth_subscription_plan');
		localStorage.removeItem('auth_subscription_start_date');
		localStorage.removeItem('auth_subscription_end_date');
		localStorage.removeItem('auth_subscription_is_valid');
		
	    const subscription_plan = localStorage.getItem('auth_subscription_plan');
	    const subscription_start_date = localStorage.getItem('auth_subscription_start_date');
	    const subscription_end_date = localStorage.getItem('auth_subscription_end_date');
	    const subscription_is_valid = localStorage.getItem('auth_subscription_is_valid');

	    return { subscription_plan, subscription_start_date, subscription_end_date, subscription_is_valid };
	},
	isLoggedIn: () => {
		if(localStorage.getItem('auth_first_name') && localStorage.getItem('auth_last_name') && localStorage.getItem('auth_email')
		&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
			return true;
		}else{
			return false;
		}
	},
	isSubscribed: () => {
		if((localStorage.getItem('auth_subscription_plan') == 'monthly' ||  localStorage.getItem('auth_subscription_plan') == 'yearly')
		&& (localStorage.getItem('auth_subscription_start_date') != 'none' && localStorage.getItem('auth_subscription_end_date') != 'none')
		&& (localStorage.getItem('auth_subscription_is_valid') == true || localStorage.getItem('auth_subscription_is_valid') == 'true' || localStorage.getItem('auth_subscription_is_valid') == 1)){
			return true;
		}else{
			return false;
		}
	},
};

export default AuthUtility;
