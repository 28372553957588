import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AuthUtility from './auth/AuthUtility';
import AxiosApiClient from '../utils/AxiosApiClient';
import LoadingSpinner from './LoadingSpinner';

import $ from "jquery";
import swal from 'sweetalert';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function Search(){
	
	const navHistory = useNavigate();
  
    // Initial states
    const [isLoading, setIsLoading] = useState(false);
	const [searchClassesInput, setSearchClassesInput] = useState({
		search_terms: '',
		group_title: 'Any',
		searched_once: false,
		errorList: [],
		errorStyle: [],
	});
	const [searchClassesResults, setSearchClassesResults] = useState([]);

	const handleSearchClassesInput = (event) => {
		event.persist();
		
	    setSearchClassesInput({...searchClassesInput, [event.target.name]: event.target.value });
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (searchClassesInput.errorList.hasOwnProperty(event.target.name)){
			delete searchClassesInput.errorList[event.target.name];
			delete searchClassesInput.errorStyle[event.target.name];
		}
	}
	const searchClassesSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);
		
	    setSearchClassesInput({...searchClassesInput, searched_once: true });

		//values sent to api
		const payload = {
			search_terms: searchClassesInput.search_terms,
			group_title: searchClassesInput.group_title,
		}
		
		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchDataSearchClasses = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('post', '/api/search_classes', payload, {});

				setApiDataSearchClasses(response);
			} catch (error) {
				handleApiErrorSearchClasses(error);
			}
		};
	
		fetchDataSearchClasses();
	}

    function setApiDataSearchClasses(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				
				setSearchClassesResults(response.data.search_classes_results);
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				
				
			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED

				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				console.log('[searchClassesSubmit] response.data.validation_errors: ',response.data.validation_errors);

				//validation errors mapped to input fields
				setSearchClassesInput({...searchClassesInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
			}else{//more errors
			}
		}

		setIsLoading(false);

		setTimeout(() => {
			console.log('[searchClassesSubmit] searchClassesInput: ',searchClassesInput);
			console.log('[searchClassesSubmit] searchClassesResults: ',searchClassesResults);
		  }, 1000);
		  
	}
    function handleApiErrorSearchClasses(error){
		console.log('[searchClassesSubmit] error: ',error + ' back-end api call error');

		setIsLoading(false);
    }

    const handleBookClass = (i) => {

		var class_group_id = searchClassesResults[i].group_id;
		var class_random_id = searchClassesResults[i].random_id;
		var class_title = searchClassesResults[i].title;

		if(!AuthUtility.isLoggedIn()){
			AuthUtility.clearAuthData();

			swal("Info","Please login to book "+class_title,"info");
		}else if(!AuthUtility.isSubscribed()){
			
			//swal("Info","<div className='font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify'>Please subscribe to book "+class_title+'. To subscribe navigate to your account page.</div>',"info");
			swal({
				title: "Subscribe to Book a Class",
				text: "Please subscribe to book "+class_title+ ". Subscription options are in your Account.",
				icon: "info",
				buttons: {
				  cancel: "Ok",
				  confirm: {
					text: "Go to Account",
					value: true,
				  },
				},
			  }).then((value) => {
				if (value) {
					navHistory('/member/account');
				}
			  });
		}else{
			
			$('#button_'+i).addClass('hide');
			$('#loading_spinner_'+i).removeClass('hide');
				
			//values sent to api
			const payload = {
				class_group_id: class_group_id,
				class_random_id: class_random_id,
				class_title: class_title,
			}

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchDataBookClass = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('post', '/api/book_class', payload, {});

					setApiDataBookClass(response, i);
				} catch (error) {
					handleApiErrorBookClass(error, i);
				}
			};
		
			fetchDataBookClass();
		}

	}
    function setApiDataBookClass(response, i){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('handleBookClass: ',response.data.message);

				$('#button_'+i).addClass('disabled').html('Booked');

				swal("Success",response.data.message,"success");
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('handleBookClass error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
				
			}else{//more errors
			}
		}

		$('#button_'+i).removeClass('hide');
		$('#loading_spinner_'+i).addClass('hide');
	}
    function handleApiErrorBookClass(error, i){
		console.log('handleBookClass error: ',error + ' back-end api call error');

		$('#button_'+i).removeClass('hide');
		$('#loading_spinner_'+i).addClass('hide');

		swal("Error",error,"error");
    }

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Search</div>
					</div>
				</div>
			</div>
			
			<form onSubmit={searchClassesSubmit} className="">
			<div className="panel large pt-10">
				<div className="grid-x">
					<div className="large-7 medium-7 small-7 cell text-left pr-5">
						<input type="text" name="search_terms" onChange={handleSearchClassesInput} value={searchClassesInput.search_terms} className="input-group-fieldx mt-10" placeholder='Search Terms'/>
					</div>
					<div className="large-4 medium-3 small-2 cell text-left pr-5">
						<span className={searchClassesInput.errorStyle.category_id}>{searchClassesInput.errorList.category_id}</span>
						<select name="group_title" onChange={handleSearchClassesInput} value={searchClassesInput.group_title}>
							<option value="Any">Any</option>
							<option value="Yoga">Yoga</option>
							<option value="HIIT">HIIT</option>
						</select>
					</div>
					<div className="large-1 medium-2 small-3 cell text-right">
						<button type="submit" className="button width-auto uppercase">Search</button>
					</div>
				</div>
			</div>
			</form>

			{isLoading ? (
			<LoadingSpinner paddingClass="" />
			) : (
				searchClassesInput.errorList.search_terms ? (
					<div className="panel large">
						<div className="grid-x bg-light-red b1-aaa p-10">	
							<div className="large-12 medium-12 small-12 cell pl-10l-0s">
								<div className="font-raleway font-medium font-weight-500 txt-000 text-left-center-on-small">{searchClassesInput.errorList.search_terms}</div>
							</div>
						</div>
					</div>
				):(
					searchClassesResults.length === 0 && searchClassesInput.searched_once ? (
						<div className="panel large">
							<div className="grid-x bg-fafafa b1-ddd p-10">	
								<div className="large-12 medium-12 small-12 cell pl-10l-0s">
									<div className="font-raleway font-medium font-weight-500 txt-000 text-left-center-on-small uppercase">No Search Results.</div>
								</div>
							</div>
						</div>
					) : (
						searchClassesResults.length > 0 ? (
							<div className="panel large">
							{searchClassesResults.map((search_classes_result_item, i) => (
							<div className="grid-x bg-eee b1-ddd p-10 mt-10">
								<div className="large-2 medium-3 small-12 cell hide-for-small-only">
									<div className="text-left pr-10 hide-for-small-only">
										<img src={search_classes_result_item.image_url} alt="class image" width="100%" />
									</div>
								</div>
								<div className="large-8 medium-6 small-12 cell pl-10l-0s">
									<div className="font-raleway font-medium font-weight-700 txt-333 text-left-center-on-small uppercase">{search_classes_result_item.title}</div>
									<div className="text-center show-for-small-only pt-20">
										<img src={search_classes_result_item.image_url} alt="class image" width="50%"/>
									</div>
									<div className="font-raleway font-medium font-weight-500 txt-222 text-left-center-on-smallx line-height-125per justify pt-10">
									{search_classes_result_item.description}
									</div>
								</div>
								<div className="large-2 medium-3 small-12 cell text-right-center-on-small">
									<div className="pt-20l-10s show-for-small-only"></div>
									<div>
										<Link to="" onClick={() => handleBookClass(i)} id={"button_"+i} className="button small uppercase" >Book</Link>
										<div id={"loading_spinner_"+i} className="text-right-center-on-small hide">
										<LoadingSpinner paddingClass="none" sizeClass="small"/>
										</div>
									</div>
								</div>
							</div>
							))}
							</div>
						):(
							<></>
						)
					)
				)
			)}

		</div>
	);
}

export default Search;