// ClassStatusUpdater.js

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';

import swal from 'sweetalert';

const useClassStatusUpdater = (setUserClasses) => {

	const navHistory = useNavigate();

    const [isLoadingAtTop, setIsLoadingAtTop] = useState(false);

    const handleUpdateUserClassStatus = async (event, user_class, new_class_status) => {
        event.preventDefault();

        if (!AuthUtility.isLoggedIn()) {
            AuthUtility.clearAuthData();
            swal("Info", "Please login to book, view, or cancel " + user_class.avw_class.title, "info");
            navHistory('/login');
        } else if (!AuthUtility.isSubscribed()) {
            swal({
                title: "Subscribe to Book and Cancel a Class",
                text: "Please subscribe to book, view, or cancel " + user_class.avw_class.title + ". Subscription options are in your Account.",
                icon: "info",
                buttons: {
                    cancel: "Ok",
                    confirm: {
                        text: "Go to Account",
                        value: true,
                    },
                },
            }).then((value) => {
                if (value) {
                    navHistory('/member/account');
                }
            });
        } else {
            setIsLoadingAtTop(true);

            var user_class_random_id = null;

            if (typeof user_class === 'object' && user_class !== null) {
                console.log("user_class: "+user_class);
                user_class_random_id = user_class.random_id;
            } else if (typeof user_class === 'string') {
                console.log("user_class_random_id: "+user_class);
                user_class_random_id = user_class;
            } else {
                console.log("user_class is neither an object nor a string: "+user_class);
            }

            const payload = {
                user_class_random_id: user_class_random_id,
                new_class_status: new_class_status,
            }

            var auth_api = 'phpLaravel';
            if (localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== '') {
                auth_api = localStorage.getItem('auth_api');
            }

            const fetchDataUpdateUserClassStatus = async () => {
                try {
                    const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
                    await getBearerToken();
                    const response = await makeRequestWithHeaders('post', '/api/update_user_class_status', payload, {});
                    setApiDataUpdateUserClassStatus(response, user_class, new_class_status);
                } catch (error) {
                    handleApiErrorUpdateUserClassStatus(error);
                }
            };

            fetchDataUpdateUserClassStatus();
        }
    };

    const setApiDataUpdateUserClassStatus = (response, user_class, new_class_status) => {
        if (response && response.data) {
            if (response.data.status === 200) {
                console.log('handleUpdateUserClassStatus: ', response.data.message);

                if (new_class_status === 'canceled') {
                    setUserClasses(prevClasses => prevClasses.filter(c => c.random_id !== user_class.random_id));
                } else if (new_class_status === 'rebook') {
                    setUserClasses(prevClasses => [response.data.user_class, ...prevClasses]);
                } else if (new_class_status === 'viewed') {
                    navHistory('/member/booked');
                }

                swal("Success", response.data.message, "success");
            } else if (response.data.status === 401) {
                console.log('handleCancelClass error: ', response.data.message + ' back-end api call error');
                AuthUtility.clearAuthData();
                swal("Warning", response.data.message, "warning");
            }
        }

        setIsLoadingAtTop(false);
    };

    const handleApiErrorUpdateUserClassStatus = (error) => {
        console.log('handleUpdateUserClassStatus error: ', error + ' back-end api call error');
        setIsLoadingAtTop(false);
        swal("Error", error, "error");
    };

    return { handleUpdateUserClassStatus, isLoadingAtTop };
};

export default useClassStatusUpdater;
