import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../../components/frontend/auth/AuthUtility';

import swal from 'sweetalert';

import home_icon from '../../assets/frontend/images/home_icon.png';
import yoga_icon from '../../assets/frontend/images/yoga_icon.png';
import hiit_icon from '../../assets/frontend/images/hiit_icon.png';
import search_icon from '../../assets/frontend/images/search_icon.png';
import bookings_icon from '../../assets/frontend/images/bookings_icon.png';
import account_icon from '../../assets/frontend/images/account_icon.png';

const OnCanvasNavigation = () => {
	
	//logout should be its own component
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);

	const logoutSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiData(response);
			} catch (error) {
			  handleApiError(error);
			}
		};
	  
		fetchData();
	}
    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		setIsLoading(false);
		
		navHistory('/login');
    }


	return(
		<div className="sticky-bottom z-index-1010">
			<div className="sticky bg-eee bt1-ddd plr-20l-10sz ptb-10">
				
				<div className="panel large">
					<div className="grid-x">
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/home" className="button icon-sticky-bottom image-container no-underline">
							<img src={home_icon} alt="add icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">Home</div>
							<img src={home_icon} alt="add icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">Home</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/yoga" className="button icon-sticky-bottom image-container no-underline" >
							<img src={yoga_icon} alt="yoga_icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">Yoga</div>
							<img src={yoga_icon} alt="yoga_icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">Yoga</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/hiit" className="button icon-sticky-bottom image-container no-underline" >
							<img src={hiit_icon} alt="hiit_icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">HIIT</div>
							<img src={hiit_icon} alt="hiit_icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">HIIT</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/search" className="button icon-sticky-bottom image-container no-underline" >
							<img src={search_icon} alt="search_icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">Search</div>
							<img src={search_icon} alt="search_icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">Srch</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/booked" className="button icon-sticky-bottom image-container no-underline" >
							<img src={bookings_icon} alt="bookings_icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">Booked</div>
							<img src={bookings_icon} alt="bookings_icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">Bked</div>
							</Link>
						</div>
						<div className="large-2 medium-2 small-2 cell text-center">
							<Link to="/member/account" className="button icon-sticky-bottom image-container no-underline" >
							<img src={account_icon} alt="account_icon" width="40" className="pb-5 hide-for-small-only"/>
							<div  className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase hide-for-small-only">Account</div>
							<img src={account_icon} alt="account_icon" width="30" className="pb-5 show-for-small-only"/>
							<div  className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase show-for-small-only">Acct</div>
							</Link>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
}

export default OnCanvasNavigation;