import React from 'react';
import {Link} from 'react-router-dom';

import $ from "jquery";

import ally_main_image from '../../assets/frontend/images/ally_main.jpeg';
import ally_main_mobile_image from '../../assets/frontend/images/ally_main_mobile.jpeg';
import ally_1_image from '../../assets/frontend/images/ally_1.jpeg';
import ally_2_image from '../../assets/frontend/images/ally_2.jpeg';
import ally_3_image from '../../assets/frontend/images/ally_3.jpeg';

function Home(){

	var RegisterOrDashboard = "/register";
	var LoginOrSchedule = "/login";

    if(localStorage.getItem('auth_users_name') && localStorage.getItem('auth_email')
	&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
		var RegisterOrDashboard = "/member/dashboard";
		var LoginOrSchedule = "/member/schedule";
	}

	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="text-overlay-wrapper text-center">

				<div className="text-overlay-1020-top50 z-index-1000x hide-for-small-only">
					<div className="clearfix position-align-top pt-40 text-center">
						<span className="pl-20 left hide-for-780px"><Link to={RegisterOrDashboard} className="button large width-225px">Join Today</Link></span>
						<span className="pr-20 right hide-for-780px"><Link to={LoginOrSchedule} className="button large width-225px">Login To Book A Class</Link></span>
						<span className="pl-20 left show-for-780px"><Link to={RegisterOrDashboard} className="button large width-175px">Join Today</Link></span>
						<span className="pr-20 right show-for-780px"><Link to={LoginOrSchedule} className="button large width-175px">Book A Class</Link></span>
					</div>
				</div>
				<img src={ally_main_image} className="hide-for-small-only"/>
				<img src={ally_main_mobile_image} className="show-for-small-only"/>
			</div>

			<div className="panel large show-for-small-only">
				<div className="grid-x pt-10 plr-10x">
					<div className="small-6 cell text-left">
						<Link to={RegisterOrDashboard} className="button width-200px-135px">Join Today</Link>
					</div>
					<div className="small-6 cell text-right">
						<Link to={LoginOrSchedule} className="button width-200px-135px"><span className="hide-for-420px">Login To </span>Book A Class</Link>
					</div>
				</div>
			</div>

			<div className="panel large pt-40">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase text-center">Join The Von Wolf Pack</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
						Welcome, we are so happy to have you! Come sweat with Ally online with heart pumping HIIT style sculpt classes and strength focused power yoga classes.
						</div>
					</div>

					<div className="large-4 medium-4 small-12 cell text-center pt-20 pr-10l-0s">
						<img src={ally_1_image} alt="ally 1"/>
					</div>
					<div className="large-4 medium-4 small-12 cell text-center pt-20 plr-5l-0s">
						<img src={ally_2_image} alt="ally 2"/>
					</div>
					<div className="large-4 medium-4 small-12 cell text-center pt-20 pl-10l-0s">
						<img src={ally_3_image} alt="ally 3"/>
					</div>
				</div>
			</div>
			
			<div className="panel large pt-40">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell">
						<div className="font-raleway font-x-large font-weight-700 txt-333 text-center">SIGN UP</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per text-center pt-20">
						Check out the class descriptions to see what suits your body today!
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pr-5l-5m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center uppercase">Full Body HIIT</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Challenge yourself with a high intensity interval style sculpt class that combines body weight movements with free hand weights to increase your strength and get your heart rate up.
							</div>
							<div className="text-center pt-20">
								<Link to="/hiit"  className="button width-125px-100px" >View</Link>
							</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pl-5l-5m-0s pr-5l-0m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center uppercase">Lower Body HIIT</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Challenge yourself with a high intensity interval style sculpt class that combines body weight movements with free hand weights to increase your strength and get your heart rate up.
							</div>
							<div className="text-center pt-20">
								<Link to="/hiit"  className="button width-125px-100px" >View</Link>
							</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pl-5l-0m-0s pr-0l-5m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center uppercase">Upper Body HIIT</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Challenge yourself with a high intensity interval style sculpt class that combines body weight movements with free hand weights to increase your strength and get your heart rate up.
							</div>
							<div className="text-center pt-20">
								<Link to="/hiit"  className="button width-125px-100px" >View</Link>
							</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pl-0l-5m-0s pr-5l-0m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center uppercase">Core HIIT</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Challenge yourself with a high intensity interval style sculpt class that combines body weight movements with free hand weights to increase your strength and get your heart rate up.
							</div>
							<div className="text-center pt-20">
								<Link to="/hiit"  className="button width-125px-100px" >View</Link>
							</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pl-5l-5m-0s pr-5l-5m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center uppercase">Cardio HIIT</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Challenge yourself with a high intensity interval style sculpt class that combines body weight movements with free hand weights to increase your strength and get your heart rate up.
							</div>
							<div className="text-center pt-20">
								<Link to="/hiit"  className="button width-125px-100px" >View</Link>
							</div>
						</div>
					</div>
					<div className="large-4 medium-6 small-12 cell pt-20 pl-5l-5m-0s">
						<div className="bg-fafafa b1-ddd p-20">
							<div className="font-raleway font-x-large font-weight-700 txt-333 text-center">POWER YOGA</div>
							<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per justify pt-20">
							Expect an invigorated, intelligently sequenced power yoga class to encourage you to find your physical barriers and break past them. Build strength while creating flexibility and mobility in your body.
							</div>
							<div className="text-center pt-20">
								<Link to="/power_yoga"  className="button width-150px" >View</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="panel large pt-40">
				<div className="grid-x bg-fafafa b1-ddd p-20">
					<div className="large-12 medium-12 small-12 cell text-center pb-10">
						<div className="font-raleway font-x-large font-weight-700 txt-333">ALLY VON WOLF POWERED BY</div>
						<div className="text-center pt-20">
						<img src="https://us04st1.zoom.us/static/94031/image/new/ZoomLogo.png" alt="zoom" className="hide-for-small-only" width="90"/>
						<img src="https://us04st1.zoom.us/static/94031/image/new/ZoomLogo.png" alt="zoom" className="show-for-small-only" width="70"/>
						</div>
						<div className="font-raleway font-medium font-weight-500 txt-222 line-height-125per text-center pt-30 pb-10">
						You will need the <span className="font-weight-700 uppercase">zoom app</span> installed on your device to view class.
						</div>
					</div>
					<div className="large-4 medium-12 small-12 cell text-center pt-20 pr-5l-0s">
						<Link to="https://itunes.apple.com/us/app/id546505307" className="button width-225px" target="_blank"><i className="fab fa-apple fab-med"></i>&nbsp;&nbsp;Download in App Store</Link>
					</div>
					<div className="large-4 medium-12 small-12 cell text-center pt-20 plr-5l-0s">
						<Link to="https://play.google.com/store/search?q=zoom&c=apps" className="button width-225px" target="_blank"><i className="fab fa-android fab-med"></i>&nbsp;&nbsp;Download in App Store</Link>
					</div>

					<div className="large-4 medium-12 small-12 cell text-center pt-20 pl-5l-0s">
						<Link to="https://zoom.us/client/latest/ZoomInstaller.exe" className="button width-225px" target="_blank"><i className="fas fa-laptop fas-med"></i>&nbsp;&nbsp;Download Zoom Client</Link>
					</div>
				</div>
			</div>

		</div>
	);
}

export default Home;